<template>
  <div
    id="cart-app"
    class="cart-app cart-v2"
    @touchstart="onTouchStart"
  >
    <div class="cart-header j-cart-header-wrap">
      <client-only>
        <button
          v-if="showMiniCart"
          @click="openMiniCart"
        >
          打开迷你购物车
        </button>
      </client-only>
      <cartHeaderV2 v-if="cartBottomStyle" />
      <cartHeader v-else />
      <cartTop
        v-if="hasFetchCart"
        @login-handle="clickLogin"
      />
    </div>
   
    <!-- page loading -->
    <div
      v-if="pageLoading && !isSSR"
      class="c-skeleton__cart-container"
    >
      <s-loading
        type="newpage"
        :show="true"
        :new-page-delay="false"
      />
    </div>
    <CartList
      v-if="hasFetchCart"
      @cartListIsMounted="cartListIsMounted"
      @noMoreItemRender="noMoreItemRender"
      @renderRecommend="renderRecommend"
      @scrollToCartItem="(cartId) => anchorToCartItem(cartId, { highlightAnimate: false, duration: 1, rate: 0 })"
    />
    <!--空购物袋-->
    <emptyCart
      v-if="hasFetchCart && cartInfo.cartSumQuantity == 0"
      @clickLogin="clickLogin"
    />
    <template v-if="showEmptyCartUnpaidOrderTip && hasFetchCart">
      <EmptyCartUnpaidOrderTipV3
        v-if="showEmptyCartUnpaidOrderTipV3"
        :unpaid-order-info="orderState.unpaidOrderInfo"
        :language="language"
      />
      <EmptyCartUnpaidOrderTipV2
        v-else-if="showEmptyCartUnpaidOrderTipV2"
        :unpaid-order-info="orderState.unpaidOrderInfo"
        :language="language"
        :locals="locals"
      />
      <EmptyCartUnpaidOrderTip
        v-else
        :unpaid-order-info="orderState.unpaidOrderInfo"
        :locals="locals"
        :language="language"
      />
    </template>
    <EmptyCartCanceledOrderTip
      v-if="showEmptyCartCanceledOrderTip && hasFetchCart"
      :canceled-order-info="orderState.canceledOrderInfo"
      :language="language"
    />
    <client-only>
      <CartSupportInfoContainer v-if="showPaymentSafetyCart && rendSupportInfo" />
    </client-only>
    <client-only>
      <recommend-wrapper-comp
        v-if="!pageLoading && hasFetchCart"
        v-show="!hiddenRecommend"
        id="j-cart-container__recommend"
        ref="recommendWrapper"
        class="cart-container__recommend"
        :should-reload="shouldReloadRecommend"
        :need-render="needRender"
        @reload-success="handleRecommendReloadSucess"
      />
    </client-only>
    <cartFooter
      v-if="carts.length && hasFetchCart"
      ref="cartFooter"
      :show-coupon-helper-enter="showCouponHelperEnter"
      @open-coupon-helper-list="openCouponHelperList"
    />
    <!-- 售罄商品、优惠券助手组件滑出层按需加载 -->
    <client-only>
      <!-- 批处理 -->
      <transition name="vue-slide-layer-bottom-fade">
        <cart-batch-footer v-if="batchActive" />
      </transition>
      <keep-alive>
        <component :is="dialogComponent.other" />
      </keep-alive>
      <FullGiftDrawer v-if="asyncComponentLoadedStatus.cartFullGiftsDrawIsLoaded" />
      <AddBuyDrawer v-if="asyncComponentLoadedStatus.cartAddBuyDrawIsLoaded" />
      <DiscountDetailDrawer v-if="asyncComponentLoadedStatus.cartDiscountDetailDrawIsLoaded" />
      <!--  通用提示组件按需加载    -->
      <component :is="modalOptions.component" />
      <s-loading
        type="curpage"
        mask-transparent
        :show="loadingShow"
      />
      <CartSoldOut v-if="asyncComponentLoadedStatus.soldOutDialogIsLoaded" />
      <CartNegativeInformation v-if="asyncComponentLoadedStatus.negativeInformationDrawIsLoaded" />
      <CanceledOrderProductList v-if="asyncComponentLoadedStatus.canceledOrderProductListIsLoaded" />
      <PriceChangeDetails v-if="asyncComponentLoadedStatus.priceChangeDetailsDrawIsLoaded" />
      <!-- 领券弹窗 -->
      <GetCouponDrawer v-if="carts.length && !isSSR && drawerGetCoupon" />
      <BuyBoxDrawer
        v-if="carts.length && !isSSR"
        ref="buyBoxDrawerInstance"
        @onReady="onBuyBoxDrawerReady"
        @onBuyBoxOpen="onBuyBoxOpen"
        @onBuyBoxClose="onBuyBoxClose"
        @openQuickView="openQuickView"
      >
        <template #cardPriceTop>
          <div
            :style="buyBoxPriceTop.style"
          >
            <span v-html="buyBoxPriceTop.text"></span>
          </div>
        </template>
      </BuyBoxDrawer>
      <NoNetworkEmpty
        class="cart-container__no-network-empty"
        :is-empty="hasFetchCart && cartInfo.cartSumQuantity == 0"
        :language="language"
      />

      <!-- 底部活动券融合新手引导 -->
      <BottomPromoGuide
        v-if="bottomPromoGuideShow"
        :language="language"
        @close="onBottomPromoGuideClose"
      />
      <NewNavBarGuide
        v-if="newNavBarGuideShow"
        @close="onNewNavBarGuideClose"
      />
      <div
        v-if="showMask"
        class="cart-mask"
      ></div>
    </client-only>
  </div>
</template>
<script>
import { onMounted, getCurrentInstance, defineComponent, defineAsyncComponent, nextTick } from 'vue'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { SLoading } from '@shein-aidc/sui-loading/mobile'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import cartHeader from './page/header/index.vue'
import cartHeaderV2 from './page/header/index2.vue'
import cartTop from './page/top/index.vue'
import cartFooter from './page/footer/index.vue'
import emptyCart from './page/emptyCart/index.vue'
import CartList from './page/list/CartList.vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import cartGuideManager from './components/cartGuideManager/index.js'
import { useBottomPromoGuide } from './components/cartGuideManager/bottomPromo/useBottomPromoGuide.js'
import { useNewNavBarGuide } from './components/cartGuideManager/newNavBar/useNewNavBarGuide.js'
import checkoutIncentive from './components/cartCheckoutIncentive/checkoutIncentive.js'
import prerenderCheckout from './utils/prerender.js'
import { web_cart_route_enter } from './utils/metricTagsConfig.js'
import { getBrowserName, scrollToCartItem, getUrlQueryString, cartEmphasizePromotionAll } from './utils'
import { USE_BFF_API_KEYS } from './utils/constants'
import { prefetchResource } from 'public/src/services/prefetchResource/index.js'
import { getQueryString, removeLocalStorage } from '@shein/common-function'
import { getCouponDataForAddOnItem } from '@/public/src/pages/common/add-on/utils/couponData.js'
import { markPoint } from 'public/src/services/mark/index.js'
import { isLogin as isLoginFn } from 'public/src/pages/common/utils/index.js'
import { MonitorUserInteraction, insertRecommendGuideB, checkRecommendInView } from '@/public/src/pages/cart_v2/components/recommendGuide/utils.js'
/** Event listener **/
import { bindContainerScrollListener } from './eventListener/scroll'
import { exposeScenesAbtAggregator } from 'public/src/pages/cart_v2/utils/EventAggregator.js'
import { getUserActionTrackerData, handleStartCollectData, onUserTracekConfigMerge } from 'public/src/pages/common/userActionTracker/core'
import BottomPromoGuide from './components/cartGuideManager/bottomPromo/BottomPromoGuide.vue'
import NewNavBarGuide from './components/cartGuideManager/newNavBar/NewNavBarGuide.vue'
import { useLastLureBubble } from 'public/src/pages/product_app/footer/cart/hooks/useLastLureBubble.js'
import { usePriceChangeState } from 'public/src/pages/cart_v2/components/cartPriceChange/usePriceChangeState.js'
import { useCartFloorDrawer } from 'public/src/pages/cart_v2/components/cartFloorDrawer/hooks/useCartFloorDrawer.js'
import { onMountedOrActivated } from 'public/src/pages/cart_v2/hooks/onMountedOrActivated.js'
import { useUpdateGuideState } from 'public/src/pages/cart_v2/utils/popoverManager/useUpdateGuideState.js'
import { setupHydrationVue } from 'public/src/spaHydration/index.js'
import { useUpdateModalState } from 'public/src/pages/cart_v2/utils/popoverManager/useUpdateModalState.js'
import { useUpdateFilterState } from 'public/src/pages/cart_v2/utils/popoverManager/useUpdateFilterState.js'
import { usePopLoader, CART_INCENTIVE_PRODUCT_LIST } from 'public/src/pages/cart_v2/hooks/usePopLoader.js'
import { useSameSellersLabelEvent } from 'public/src/pages/cart_v2/hooks/item/useSameSellersLabel.js'
import { formatToggleIncentiveProductListDialogInfo } from 'public/src/pages/cart_v2/components/incentiveProductListDrawer/format.js'
import { setNoClickCheckoutRecords, removeNoClickCheckoutRecords } from 'public/src/pages/cart_v2/components/cartCheckoutIncentive/incentiveTools.js'
import { useGuideCheckoutStateChange } from 'public/src/pages/cart_v2/components/cartItemGuideCheckout/useGuideCheckoutStateChange.js'
const ProCouponAddItems = '10000' //优惠券凑单，中间层定义活动

daEventCenter.addSubscriber({ modulecode: '1-8-1' })
daEventCenter.addSubscriber({ modulecode: '1-8-2' })
daEventCenter.addSubscriber({ modulecode: '1-8-7' })
daEventCenter.addSubscriber({ modulecode: '1-8-3' })
daEventCenter.addSubscriber({ modulecode: '1-8-4' })
daEventCenter.addSubscriber({ modulecode: '1-8-5' })
daEventCenter.addSubscriber({ modulecode: '1-8-6' })
daEventCenter.addSubscriber({ modulecode: '1-8-8' })
daEventCenter.addSubscriber({ modulecode: '1-8-9' })
daEventCenter.addSubscriber({ modulecode: '2-24' })

let isRouterLeaved = false

export default defineComponent({
  name: 'CartContainerNew',
  components: {
    cartHeader,
    cartHeaderV2,
    cartTop,
    cartFooter,
    emptyCart,
    CartList,
    ClientOnly,
    recommendWrapperComp: defineAsyncComponent(() => import(/* webpackChunkName: "cartv2_bottom_content" */'./page/recommend/index.vue')),
    CouponHelperDrawer: defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-coupon-helper" */'@/public/src/pages/cart_v2/components/couponHelper/drawer/index.vue')),
    GetCouponDrawer: defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-get-coupon" */'@/public/src/pages/cart_v2/components/getCoupon/drawer.vue')),
    DiscountDetailDrawer: defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-discount-detail" */'./components/discountDetailDrawer/item.vue')),
    EmptyCartUnpaidOrderTip: defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-order-tip" */'./components/unpaidOrderTip/EmptyCartUnpaidOrderTip.vue')),
    EmptyCartUnpaidOrderTipV2: defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-order-tip" */'./components/unpaidOrderTip/EmptyCartUnpaidOrderTipV2.vue')),
    EmptyCartUnpaidOrderTipV3: defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-order-tip" */'./components/unpaidOrderTip/EmptyCartUnpaidOrderTipV3.vue')),
    EmptyCartCanceledOrderTip: defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-order-tip" */'./components/canceledOrderTip/EmptyCartCanceledOrderTip.vue')),
    CanceledOrderProductList: defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-order-tip" */'./components/canceledOrderTip/CanceledOrderProductList.vue')),
    PriceChangeDetails: defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-price-change-details" */'./components/cartPriceChange/PriceChangeDetails.vue')),
    commonModalOptions: defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-common-modal-options" */'./components/common/commonModalOptions.vue')),
    CartSoldOut: defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-sold-out" */'./components/cartSoldOut/index.vue')),
    CartNegativeInformation: defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-negative-information" */'./components/cartNegativeInformation/index.vue')),
    NoNetworkEmpty: defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-no-network-empty" */'./components/offline/NoNetworkEmpty.vue')),
    CartBatchFooter: defineAsyncComponent(() => import(/* webpackChunkName: "cartv2_bottom_content" */'./components/cartBatchFooter/index.vue')),
    CartSupportInfoContainer: defineAsyncComponent(() => import(/* webpackChunkName: "cartv2_bottom_content" */'./components/cartSupportInfo')),
    AddBuyDrawer: defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-pick-item-drawer" */'./components/pickItemDrawer/AddBuyDrawer.vue')),
    FullGiftDrawer: defineAsyncComponent(() => import(/* webpackChunkName: "cartv2-pick-item-drawer" */'./components/pickItemDrawer/FullGiftDrawer.vue')),
    BottomPromoGuide,
    SLoading,
    NewNavBarGuide,
    BuyBoxDrawer: defineAsyncComponent(() => import(/* webpackChunkName: "plv2_BuyBoxDrawer" */ 'public/src/pages/common/BuyBoxPopup/BuyBoxDrawer.vue')),
  },
  setup() {
    setupHydrationVue()
    const vm = getCurrentInstance()
    const { getLastLureBubble, setLastLureBubble } = useLastLureBubble()
    const { bottomPromoGuideShow, onBottomPromoGuideClose } = useBottomPromoGuide()
    const { newNavBarGuideShow, onNewNavBarGuideClose } = useNewNavBarGuide()
    const { initCartPriceChaneState, resetCartPriceChangeState } = usePriceChangeState()
    const { initGuideState, resetGuideState } = useUpdateGuideState()
    const { resetModalState, registerModalState, updateModalState } = useUpdateModalState()
    const { resetFilterState } = useUpdateFilterState()
    const { open: openCartIncentiveProductList } = usePopLoader({ name: CART_INCENTIVE_PRODUCT_LIST })
    const { handleIsShowPopoverTimeChange } = useGuideCheckoutStateChange()
    const sameSellersLabelEvent = useSameSellersLabelEvent()

    initCartPriceChaneState()
  
    const { initCartFloorDrawer } = useCartFloorDrawer({ anchorToCartItem: (...args) => {
      vm.proxy.anchorToCartItem(...args)
    } })
    onMountedOrActivated(initCartFloorDrawer)
    onMountedOrActivated(initGuideState)
    onMounted(resetFilterState)
    return {
      getLastLureBubble,
      setLastLureBubble,
      resetModalState,
      resetGuideState,
      openCartIncentiveProductList,
      resetCartPriceChangeState,
      bottomPromoGuideShow,
      onBottomPromoGuideClose,
      newNavBarGuideShow,
      onNewNavBarGuideClose,
      sameSellersLabelEvent,
      registerModalState,
      updateModalState,
      handleIsShowPopoverTimeChange,
    }
  },
  async asyncData ({ store, context }) {
    store.commit('cart_v2/resetLocals')
    if (context && context.ssrPageType === 'cart') {
      store.state['cart_v2'] = Object.assign(store.state['cart_v2'], context)//updateGbSsrData
      await store.dispatch('cart_v2/initSsrData')
    } else if(typeof window != 'undefined' && window.cartSsrData){
      store.state['cart_v2'] = Object.assign(store.state['cart_v2'], window.cartSsrData)//updateGbSsrData
      await store.dispatch('cart_v2/initSsrData')
    }
    return 'uninstall'
  },
  beforeRouteEnter (to, from, next) {
    isRouterLeaved = false
    next(vm => {
      vm.handleBeforeRouteEnter(to)
    })
  },
  beforeRouteLeave (to, from, next) {
    if(to.name == 'checkout' && !window.toCheckout) {
      this.$refs.cartFooter?.$refs?.cartCheckoutVue?.goCheckout('conventional', { next: () => {
        this.handleBeforeRouteLeave()
        next()
      } })
      return
    } else if (to.name !== 'checkout') {
      prerenderCheckout.flushJob()
    }
    this.handleBeforeRouteLeave()
    this.closeDialogOnLeaveCart(['isShowCartFloorDrawer', 'showCanceledOrderDraw'])
    next()
  },
  data () {
    return {
      pageLoading: false,
      hiddenRecommend: false,
      shouldReloadRecommend: false, // 应该去刷新推荐位（scene_id: 24）
      needRender: false,
      rendSupportInfo: false,
      isFirstPageLoading: true,
      isSSR: false,
      hasFetchCart: false,
      canShowIncentivePopover: true, // 是否能展示结算按钮利诱点气泡
      showMask: true, // 是否展示遮罩,主要是修复首页多次点击购物车icon时，第二次点击事件会触发购物车的元素 UG-174061
      showMiniCart: typeof window != 'undefined' && window.gbCommonInfo.NODE_SERVER_ENV === 'localhost',
    }
  },
  computed: {
    ...mapState('cart_v2', [
      'language',
      'cartInfo',
      'dialogComponent',
      'orderState',
      'checkoutState',
      'carts',
      'loginStatus',
      'locals',
      'modalOptions',
      'loadingShow',
      'asyncComponentLoadedStatus',
      'batchActive',
      'ssrPageType',
      'cartAbtInfo',
      'couponInfo',
      'topRightsFuns',
      'mallCartBiData',
      'mallCartList',
      'cartIds',
      'filterState',
      'showRecommendGuideA',
      'monitorUserInteraction',
      'drawerGetCoupon',
      'promotionState',
      'filterState',
      'buyBoxPriceTop',
      'assistPolicyData'
    ]),
    ...mapGetters('cart_v2', [
      'needFlushPrerenderCache',
      'topShippingInfo',
      'payShowStyleA',
      'payShowStyleB',
      'cartfloorInform',
      'couponSelectType',
      'isShowNewPromoBar',
      'cartCouponEntranceLog',
      'cartRecommendGuideConfig',
      'cartLinkedRecTiming',
      'cartLinkedRecoCount',
      'cartBottomStyle',
    ]),
    ...mapState({
      rootCartInfo: state => state.cartInfo,
    }),
    // 是否展示空车状态待支付订单提示
    showEmptyCartUnpaidOrderTip() {
      return this.hasFetchCart && this.cartInfo.cartSumQuantity == 0 && this.loginStatus && this.orderState.unpaidOrderInfo
    },
    showEmptyCartUnpaidOrderTipV2() {
      return !this.orderState.unpaidOrderInfo?.benefitInfo && this.orderState.unpaidOrderInfo?.countdownStyle == '1'
    },
    showEmptyCartUnpaidOrderTipV3() {
      return !!this.orderState.unpaidOrderInfo?.benefitInfo
    },
    // 是否展示空车状态取消订单提示
    showEmptyCartCanceledOrderTip() {
      return this.hasFetchCart && this.cartInfo.cartSumQuantity == 0 && this.loginStatus && this.orderState.canceledOrderInfo
    },
    showPaymentSafetyCart() {
      return this.assistPolicyData && this.carts.length && this.cartAbtInfo?.PaymentSafetyExperiment?.param?.PaymentSafetyCart == 'Show' ? true : false
    },
    filterLabel() {
      return this.filterState.filterLabel
    },
    // 复制app的逻辑
    couponAddItemsData() {
      let res = {}
      this.mallCartList?.forEach(mall => {
        const storeFullPromotionList = mall?.promotionData?.[0]?.contentData
        storeFullPromotionList?.forEach(promotion => {
          if (promotion.groupHeadInfo?.data?.type_id == ProCouponAddItems) {
            res = promotion
          }
        })
      })
      const fullPlatformPromotionList = this.promotionState.bottomPromotionData?.[0]?.contentData
      fullPlatformPromotionList?.forEach(promotion => {
        if (promotion.groupHeadInfo?.data?.type_id == ProCouponAddItems) {
          res = promotion
        }
      })
      return res
    },
    // 是否展示券助手悬浮球入口
    showCouponHelperEnter() {
      const showCouponHelper = (this.cartAbtInfo?.['CartCouponHelpernew']?.p || '')?.split('&').includes('CouponHelperTypenew=show')
      const closeCouponHelper = this.cartAbtInfo?.['OpenCartCouponHelpernew']?.p == 'CloseCouponHelpernew'
      const usableCoupons = this.couponInfo?.cmpUsableCoupons
      const disableCoupons = this.couponInfo?.cmpDisableCoupons
      const couponCoExist = this.cartCouponEntranceLog || this.couponAddItemsData?.groupHeadInfo?.data?.addItemParams?.isAbtEnable !== '1'
      const isNewPromoBarPlanC = this.cartAbtInfo?.promotionCouponMix?.param?.promotion_coupon_mix == 'planC' // UG-260724融合与券助手共存
  
      return showCouponHelper
        && !closeCouponHelper
        && this.loginStatus
        && (usableCoupons?.length > 0 || disableCoupons?.length > 0)
        && ['couponhelper', 'both'].includes(this.couponSelectType)
        && (!this.isShowNewPromoBar || isNewPromoBarPlanC) // 
        && couponCoExist
    },
  },
  watch: {
    needFlushPrerenderCache: {
      handler (val) {
        if(typeof window == 'undefined') return
        prerenderCheckout.switchTaskState(val)
      },
      immediate: true
    },
    showCouponHelperEnter: {
      handler(newVal) {
        if (typeof window == 'undefined') return
        if (newVal) {
          daEventCenter.triggerNotice({ 
            daId: '1-8-7-3',
            extraData: { is_satisfied: this.getCouponSatisfied() }
          })
        }
      }
    },
    couponInfo: {
      handler() {
        if (typeof window == 'undefined') return
        const couponData = this.couponAddItemsData
        const isMeet = couponData?.groupHeadInfo?.data?.additionInfoList[0].isMeet
        // 券助手中全部都是不可用券，且存在符合条件的优惠券，共存abt打开
        const typeA = isMeet == '0' && couponData?.groupHeadInfo?.data?.addItemParams?.isAbtEnable == '1'
        // 当前券前置的最优券组合的优惠金额>0,或券助手中存在可用券时，共存abt打开
        const typeB = isMeet == '1' && couponData?.groupHeadInfo?.data?.addItemParams?.isAbtEnable == '1'
        exposeScenesAbtAggregator.addEvent({
          daId: '1-8-7-60',
          extraData: {
            scenes: 'cart_coupon_entrance_log',
            type: typeA ? '1' : (typeB ? '2' : '3')
          }
        })
      },
      deep: true,
    },
    topShippingInfo: {
      handler(newVal) {
        const type = newVal.freeShippingStrategy || newVal.tip ? 1 : 0
        exposeScenesAbtAggregator.addEvent({
          daId: '1-8-7-60',
          extraData: {
            scenes: 'cart_shipping_position',
            type
          }
        })
      },
      deep: true
    },
    'orderState.unpaidOrderInfo.billno': {
      handler(n) {
        if(typeof window == 'undefined') return
        // 客户端兜底, 若没有未支付订单信息, 则尝试获取
        if(!n) this.fetchUnpaidOrdersInfo()
      },
      immediate: true,
    },
    showEmptyCartUnpaidOrderTip: {
      handler(n, o) {
        if(typeof window == 'undefined') return
        if(n && !o) {
          const benefitList = []
          if(this.orderState.canceledOrderInfo?.savedPrice?.amount > 0) {
            benefitList.push('discount')
          }
          if (this.orderState.canceledOrderInfo?.benefitBiData) {
            benefitList.push(this.orderState.canceledOrderInfo.benefitBiData)
          }
          exposeScenesAbtAggregator.addEvent({
            daId: '1-8-7-60',
            extraData: {
              scenes: 'cartUnpaidOrder',
              order_id: this.orderState.canceledOrderInfo?.billno,
              benefit_list: benefitList.join('`') || 'common',
            }
          })
        }
      },
      immediate: true
    },
    mallCartBiData: {
      handler(data) {
        if (typeof window == 'undefined') return
        if (data?.trendStoreCodeList?.length) {
          exposeScenesAbtAggregator.addEvent({
            daId: '1-8-7-60',
            extraData: {
              scenes: 'store_trends_logo',
              type: data.trendStoreCodeList.join(','),
            },
          })
        }
        if (data?.exposeScenesAbtNew) {
          data?.exposeScenesAbtNew?.forEach(item => {
            exposeScenesAbtAggregator.addEvent({
              daId: '1-8-7-60',
              extraData: {
                scenes: item?.scenes,
                type: item?.type,
              },
            })
          })
        }
      },
      immediate: true,
      once: true,
    },
    filterLabel: {
      handler(n) {
        if (typeof window == 'undefined') return
        exposeScenesAbtAggregator.addEvent({
          daId: '1-8-7-60',
          extraData: {
            coupon_select: n?.biData?.couponSelect || 0
          }
        })
      },
      immediate: true,
      once: true,
    },
  },
  created() {
    if(this.ssrPageType === 'cart' || (typeof window != 'undefined' && window.cartSsrData)) {
      this.isSSR = true
      this.hasFetchCart = true
    } else {
      this.pageLoading = true
    }
    prerenderCheckout.init()
  },
  beforeMount() {
    window.CartVue = this
  },
  mounted(){
    this.fetchCouponInfo()
    bindContainerScrollListener.call(this)
    appEventCenter.on('update-cart', async (data = {}, cb) => {
      if (this.cartLeave) return
      if (data.code == 0) {
        await this.fetchCartIndex()
      }
      cb && cb()
    })

    //Safari 返回不刷新
    let self = this
    var browserRule = getBrowserName()
    if(browserRule == 'Apple Safari'){
      window.onpageshow = function(e){
        if(e.persisted || (window.performance && window.performance.navigation?.type == 2) ){
          self.changeRootSLoadingStatus({
            show: false,
          })
          self.fetchCartIndex()
        }
      }
    }
    
    this.getUseBffApi(USE_BFF_API_KEYS)

    requestIdleCallback(() => {
      prefetchResource.prefetchJs({
        prefetchList: [
          { chunkName: 'add_on_v2', relType: 'prefetch' },
          { chunkName: 'cartv2-floor-drawer', relType: 'prefetch' },
          { chunkName: 'cartv2-discount-detail', relType: 'prefetch' },
          { chunkName: 'cartv2-pick-item-drawer', relType: 'prefetch' },
          { chunkName: 'add_on_v2_item', relType: 'prefetch' },
        ]
      })
    }, { timeout: 1000 })

    if (this.cartLinkedRecTiming == 'off') return
    const currentTrackCount = +(window.sessionStorage.getItem('cart_v2_track_trigger_count') || 0)
    if (currentTrackCount >= this.cartLinkedRecoCount) return
    // 监听 beforeunload 事件，在刷新页面时触发
    window.addEventListener('beforeunload', this.handleBeforeUnload)
    onUserTracekConfigMerge( 'cart_v2', { triggerType: this.cartLinkedRecTiming })
    handleStartCollectData('cart_v2')
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  },
  activated() {
    window.TPM.TPES.switchPage('cart')
    if(!this.isFirstPageLoading) {
      this.updateAddressLocal(true)
    }
    this.isFirstPageLoading = false
    removeLocalStorage('is_combine')
    this.resetSaPageInfo()
    this.isHaveAddCart()
    this.handlerRouterQueryParamOrKeepAlive(this.isSSR)

    if(this.isSSR) {
      this.isSSR = false
      this.handleSaPageInfo({ SaPageInfo: window.SaPageInfo })
      this.handlePageView()
      markPoint({ eventName: 'setPageData', measureFrom: 'pageDataReady' })
      this.handleQueryCartData(this.cartInfo)
      return
    }

    if(this.ssrPageType !== 'cart') {
      let isLogin = isLoginFn()
      this.updateState({
        key: 'loginStatus',
        value: isLogin
      })
    }

    // 若低效用户底部推荐引导倒计时在离开购物车时还未结束，则在返回购物车时重新计时
    if (this.monitorUserInteraction && this.monitorUserInteraction.overTime === null) {
      // eslint-disable-next-line no-console
      console.log(`[cart低效状态] 返回购物车，重新计时。`)
      this.monitorUserInteraction.interactionTimer = this.monitorUserInteraction.setInteractionTimer()
    }
    // 未勾选商品进入商详再返回购物车，到达可弹出引导结算气泡的时机
    this.handleIsShowPopoverTimeChange(true)
  },
  methods: {
    ...mapActions('cart_v2', ['fetchCartIndex', 'handleCartLogin', 'fetchUnpaidOrdersInfo', 'getUseBffApi', 'fetchCouponInfo', 'handleSaPageInfo', 'closeAllDrawers']),
    ...mapMutations('cart_v2', ['updateState', 'updateAddressLocal', 'toggleCartCouponHelperDrawer', 'changeAllDiscountDetailDrawerParams', 'changeAppendageDrawerStatus', 'updateFilterState', 'changeCartAddBundlingState']),
    ...mapMutations(['changeRootSLoadingStatus']),
    /**
     * 仅测试功能
     */
    openMiniCart() {
      if (!this.showMiniCart) return
      import('public/src/pages/cart_v2/components/business/mini-cart/index.js').then(({ default: miniCart }) => {
        miniCart.open({
          analysisData: {
            state: '购物车测试state',
            page_from: '购物车',
          }
        })
      })
    },
    async clickLogin (type) {
      let _this = this
      if (type === 'banner') {
        daEventCenter.triggerNotice({ daId: '1-8-7-1' })
      }
      const { result, res } = await this.handleCartLogin({
        type,
        updateSingelDataCb: () => {
          // 登录前为空车, 则在数据返回前展示loading
          if(_this.cartInfo.cartSumQuantity == 0) _this.pageLoading = true
        }
      })
      if(res?.code == 0){
        if (!result.equalCart && !result.emptyCart) {
          SToast(_this.language.SHEIN_KEY_PWA_17961, 5000)
        }
        _this.fetchUnpaidOrdersInfo()
      }
      _this.pageLoading = false
    },
    cartListIsMounted(){
      // cartGuideManager.startGuide()
      this.initMonitorUserInteraction()
    },
    // TR-38897购物车低效用户引导至推荐
    initMonitorUserInteraction() {
      if (typeof window == 'undefined') return
      nextTick(() => {
        const { style, line, time } = this.cartRecommendGuideConfig
        if (style == 'off') return
        const showRecommendGuide = () => {
          if (style == 'A') {
            const recommendInView = checkRecommendInView()
            // 如果已经滚动至推荐位，就不需要显示气泡了
            if (recommendInView) return
            this.updateState({
              key: 'showRecommendGuideA',
              value: true
            })
          }
          if (style == 'B') {
            const store = this.$store
            insertRecommendGuideB(store)
          }
        }
        this.updateState({
          key: 'monitorUserInteraction',
          value: new MonitorUserInteraction({
            time,
            line,
            showRecommendGuide
          })
        })
        this.monitorUserInteraction.start()
      })
    },
    noMoreItemRender(){
      this.rendSupportInfo = true
    },
    handleRecommendReloadSucess() {
      this.shouldReloadRecommend = false
      this.hiddenRecommend = false
    },
    renderRecommend() {
      requestAnimationFrame(() => {
        if (this.$refs?.recommendWrapper) {
          this.needRender = false
          this.$refs.recommendWrapper.toRender()
        } else {
          this.needRender = true
        }
      })
    },
    pageOnload (saPageInfo) {
      if (!saPageInfo || !appEventCenter) return
      // window.SaPageInfo = saPageInfo 
      appEventCenter.emit('pageOnload')
    },
    resetBatchItems () {
      this.updateState({ key: 'batchEditItemIds', value: [] })
      this.updateState({ key: 'batchActive', value: false })
      this.updateState({ key: 'flashSaleOverLimit', value: {} })
      this.updateState({ key: 'openedDeleteItemId', value: '' })
    },
    handleBeforeRouteEnter (to) {
      if(typeof window != 'undefined') {
        web_cart_route_enter()
        window.toCheckout = false
        let upttDom = document.querySelector('.j-uptt')
        if(upttDom){
          upttDom.classList.add('page-cart-top') // 解决一键回到顶部按钮层级被覆盖的问题
          upttDom.style.bottom = '3rem'
        }
        setTimeout(() => {
          this.showMask = false
        }, 300)
      }
      this.updateState({ key: 'cartLeave', value: false })
      this.resetBatchItems()

      this.handleCartRouteEnterAction(to)
      // 在所有要禁止引导代码都调用后再执行引导
      // 临时方案，后续需要优化
      cartGuideManager.startGuide()
    },
    handleCartRouteEnterAction(to) {
      // 每次进入恢复成可展示指引状态
      this.canShowIncentivePopover = true
      let { type, products, cartId, promotionId, from } = Object.assign(to?.params ?? {}, to?.query ?? {})
      if (
        from === 'footer_cart' || // 底导购物车利诱气泡
        from === 'cart_floor' && this.cartfloorInform // 楼层
      ) {
        // 不展示任何东西
        cartGuideManager.setOnceDisabled(false)
        return
      }

      if (type === 'gift' && promotionId) {
        // 不展示任何东西
        cartGuideManager.setOnceDisabled()
        this.canShowIncentivePopover = false
        this.changeAppendageDrawerStatus({ promotionId })
        return
      }

      // 进入购物车滚动到商品并高亮
      if (cartId) {
        this.anchorToCartItem(cartId)
      }

      // 打开优惠明细并高亮
      if (type === 'coupon' || type === 'gift') {
        if (type === 'gift' && !products?.length) return
        cartGuideManager.setOnceDisabled()
        this.changeAllDiscountDetailDrawerParams({
          show: true,
          anchorType: type,
        })
        return
      }
      
      let cartIds = []
      let incentiveProductListDialogInfo
      if (type === 'credit_reward') {
        incentiveProductListDialogInfo = this.checkoutState?.creditRewardPopupInfo
        incentiveProductListDialogInfo && (incentiveProductListDialogInfo = { ...incentiveProductListDialogInfo, type })
        cartIds = this.checkoutState?.creditRewardPopupInfo?.cartIds || []
      } else {
        cartIds = this.checkoutState?.cartLureInfo?.cartLureList?.find?.(item => item.type == type)?.cartIds || []
        incentiveProductListDialogInfo = this.checkoutState?.cartLureInfo?.popupInfoConfig?.find?.(item => item.type == type)
        incentiveProductListDialogInfo && (incentiveProductListDialogInfo = { ...incentiveProductListDialogInfo, cartIds })
      }
      if (!cartIds || cartIds?.length === 0) {
        return
      }
      cartGuideManager.setOnceDisabled()
      if (cartIds?.length > 1) {
        if (incentiveProductListDialogInfo) this.openCartIncentiveProductList(formatToggleIncentiveProductListDialogInfo(incentiveProductListDialogInfo))
      } else if (cartIds?.length === 1) {
        this.anchorToCartItem(cartIds[0])
      }
    },
    // 锚定到某个购物车某个商品
    anchorToCartItem(cartId, opts) {
      if (!cartId || typeof window === 'undefined') return 
      cartGuideManager.setOnceDisabled()
      this.renderCartListAll = true
      this.updateState({ key: 'renderToCartId', value: cartId })
      let timer = setTimeout(() => {
        scrollToCartItem({
          id: cartId,
          smooth: true,
          rate: 1,
          highlightAnimate: true,
          ...(opts || {})
        })
        clearTimeout(timer)
        timer = null
      }, opts?.duration || 300)
    },
    handleBeforeRouteLeave () {
      // eslint-disable-next-line no-console
      console.log('-------- Leave Cart')
      isRouterLeaved = true
      document.body.classList.remove('mshe-fixed')
      this.showMask = true
      setTimeout(() => {
        // 解决CartCheckout.vue的checkoutHeightUpdate问题
        if(document.querySelector('.j-uptt')){
          document.querySelector('.j-uptt').style.visibility = 'visible'
          document.querySelector('.j-uptt').style.bottom = 'var(--scoll-to-top-bottom)'
        }
      }, 0)
      // 当用户在缓存周期内未产生加车行为，再次进入购物车，则保持原浏览坑位深度（不刷新）
      this.scrollPosition = document.documentElement.scrollTop || window.pageYOffset

      if (window.sessionStorage) {
        window.sessionStorage.setItem('cart_page_from', window.SaPageInfo.page_from)
      }

      if (!window.toCheckout) {
        setNoClickCheckoutRecords()
      } else {
        removeNoClickCheckoutRecords()
      }

      this.updateState({ key: 'cartLeave', value: true })
      this.updateState({ key: 'lastCheckStockTime', value: 0 })
      this.updateFilterState({ filterSlideShow: false })
      this.closeAllDrawers()

      this.handleIncentiveEnd()
      this.setLastLureBubble(checkoutIncentive.lastCartLure?.uniqueType)
      // 离开购物车时，若低效用户底部推荐引导倒计时还未结束，则取消计时
      if (this.monitorUserInteraction && this.monitorUserInteraction.overTime === null) {
        // eslint-disable-next-line no-console
        console.log(`[cart低效状态] 离开购物车，取消计时。`)
        clearTimeout(this.monitorUserInteraction.interactionTimer)
        this.monitorUserInteraction.interactionTimer = null
      }
      exposeScenesAbtAggregator.flush()
      exposeScenesAbtAggregator.destroy()
      this.resetCartPriceChangeState()
      this.resetGuideState()
      this.resetModalState()
    },
    saAnalysisEvent () {
      exposeScenesAbtAggregator.addEvent({
        daId: '1-8-7-60',
        extraData: {
          scenes: 'autopoint',
          max_available_point: this.cartInfo?.auto_use_points_info?.max_available_points || 0,
          auto_point_result: this.cartInfo?.auto_use_points_info?.auto_use_points > 0 ? 1 : 0,
          is_below_auto_use_point_threshold: this.cartInfo?.auto_use_points_info?.is_below_auto_use_point_threshold || 0,
        }
      })

      exposeScenesAbtAggregator.addEvent({
        daId: '1-8-7-60',
        extraData: {
          scenes: 'cart_rights_funs',
          type: this.topRightsFuns?.isNewUserStatus ? 'newuser' : this.topRightsFuns?.isOldUserFreeReturn ? 'freereturn' : this.topRightsFuns?.freeShipping ? 'freeship' : ''
        }
      })

      let hasLowInventoryItem = this.carts.find(item => item.aggregateProductBusiness?.cartAlmostPopupEntryCartItem?.cartIds?.length >= 2)
      if(hasLowInventoryItem) {
        exposeScenesAbtAggregator.addEvent({
          daId: '1-8-7-60',
          extraData: {
            scenes: 'low_inventory',
            type: '-',
          }
        })
      }

      exposeScenesAbtAggregator.addEvent({
        daId: '1-8-7-60',
        extraData: {
          scenes: 'if_pay_methods',
          type: (this.payShowStyleA || this.payShowStyleB) ? 1 : 0
        },
      })

      const hasLabel = this.carts.some(item => !!(item.product?.actTag || item.product?.actTags?.length))
      exposeScenesAbtAggregator.addEvent({
        daId: '1-8-7-60',
        extraData: {
          scenes: 'userbehavior_tips',
          type: hasLabel ?  4 : 1
        }
      })

      if (this.mallCartBiData?.exposeScenesAbt?.scenes) {
        exposeScenesAbtAggregator.addEvent({
          daId: '1-8-7-60',
          extraData: {
            scenes: this.mallCartBiData?.exposeScenesAbt?.scenes, // mall_quickoperation"
            type: this.mallCartBiData?.exposeScenesAbt?.type,
          }
        })
      }
      if (this.mallCartBiData?.holidayDeliveryExposeScene) {
        exposeScenesAbtAggregator.addEvent({
          daId: '1-8-7-60',
          extraData: {
            scenes: this.mallCartBiData.holidayDeliveryExposeScene, // holidayDeliveryShow
          }
        })
      }

      // 新品推荐
      exposeScenesAbtAggregator.addEvent({
        daId: '1-8-7-60',
        extraData: {
          scenes: 'cart_new_recommend',
          type: this.mallCartBiData?.newRecommendAllMainItemGoodsId || '',
        }
      })

      // 用户评论行为标签
      const goodsIdArr = this.carts?.filter?.(v => v.hasCommentTag)?.map(c => c?.product?.goods_id) || []
      if (goodsIdArr?.length) {
        exposeScenesAbtAggregator.addEvent({
          daId: '1-8-7-60',
          extraData: {
            scenes: 'commentstag',
            type: Array.from(new Set(goodsIdArr)).join(',')
          }
        })
      }
      if(this.mallCartBiData?.rankListExposeScenesAbt?.scenes) {
        exposeScenesAbtAggregator.addEvent({
          daId: '1-8-7-60',
          extraData: {
            scenes: this.mallCartBiData?.rankListExposeScenesAbt?.scenes, // ranklist_detail_tips"
            type: this.mallCartBiData?.rankListExposeScenesAbt?.type,
          }
        })
      }
    },
    getCouponSatisfied() {
      let is_satisfied = ''
      const hasAvailableCoupon = this.couponInfo.cmpUsableCoupons?.length > 0
      const hasDisableCoupon = this.couponInfo.cmpDisableCoupons?.length > 0
      if (hasAvailableCoupon && hasDisableCoupon) {
        is_satisfied = 'part'
      } else if (hasAvailableCoupon) {
        is_satisfied = 'all'
      } else {
        is_satisfied = 'none'
      }
      return is_satisfied

    },
    openCouponHelperList() {
      daEventCenter.triggerNotice({
        daId: '1-8-7-10',
        extraData: { is_satisfied: this.getCouponSatisfied() },
      })
      this.toggleCartCouponHelperDrawer({ value: true })
    },
    resetSaPageInfo: function () {
      if (typeof window === 'undefined') return
      let pageFrom = ''
      // from button bar
      const f = this.$route?.query?.f || ''
      if (f) {
        pageFrom = f == 'btb' ? 'button_bar' : f
      } else {
        pageFrom = window.SaPageInfo.page_name || 'page_other'
      }
      let shop_id = ''
      if(document.referrer) {
        const referrerSearch = document.referrer.split('?')[1] || ''
        shop_id = getUrlQueryString('store_code', referrerSearch)
      }
      // 路由进入手动刷新数据
      window.SaPageInfo = {
        page_id: 8,
        page_name: 'page_cart',
        page_param: {
          is_combine: 1,
          page_from: pageFrom,
          store_code: shop_id,
          mall_code: this.mallCartList?.map(item => item.mall_code).join(',') || '',
          label_id: this.filterState.selectFilterLabel?.labelId || 0,
          all_row_count: this.cartInfo?.allProductLineSumQuantity || 0,
          row_count: this.cartInfo?.effectiveProductLineSumQuantity || 0,
          special_count: this.mallCartBiData?.lowStockSpecialCount || 0,
          almostsoldout: this.mallCartBiData?.almostSoldOutProductLineSumQuantity || 0,
          mallswitch: this.mallCartBiData?.otherMallProductLineSumQuantity || 0,
          skuswitch: this.mallCartBiData?.otherAttrProductLineSumQuantity || 0,
          bag_goods_count: this.cartInfo?.cartSumQuantity || 0,
          rrp: this.mallCartBiData?.showRrpProductLineSumQuantity || 0,
        },
        start_time: new Date().getTime()
      }
      // eslint-disable-next-line no-console
      console.log('SaPageInfo',  window.SaPageInfo)
    },
    // 是否有加车动作
    isHaveAddCart() {
      const beforeActivateCarts = this.cartInfo?.cartSumQuantity || 0
      const newActivateCarts = this.rootCartInfo?.sum || 0
      const haveAddCart = newActivateCarts > beforeActivateCarts // 有加车动作（包含了从无到有）
      const haveItemsToEmpty = beforeActivateCarts > 0 && newActivateCarts === 0 // 是否从有到无
      if(haveAddCart || haveItemsToEmpty) {
        // 刷新recommendWrapper内的推荐位
        this.shouldReloadRecommend = true
        nextTick(() =>{
          window.scrollTo({ top: 0 })
        })
      } else {
        nextTick(() =>{
          window.scrollTo({ top: this.scrollPosition || 0 })
        })
      }
    },
    async handlerRouterQueryParamOrKeepAlive(isSSR){
      if (typeof window === 'undefined') return
      const couponData = getCouponDataForAddOnItem()
      const from = getQueryString({ key: 'from' })
      let reqParams = null
      if (from === 'cart_floor') {
        reqParams = {}
      }
      if (from === 'footer_cart') {
        reqParams = {}
      }
      if(couponData?.couponInfo?.couponCode){
        reqParams = { coupon_code: couponData.couponInfo.couponCode }
      }
      if(reqParams || !isSSR){
        if(!isSSR){
          markPoint('queryCartData', 'public')
        }
        if (!reqParams) reqParams = {}
        reqParams.hasWishListTab = ''
        reqParams.filterTagId = ''
        reqParams.addItemTabParams = null
        await this.fetchCartIndex({ params: reqParams })
        if(!isSSR){
          this.hasFetchCart = true
          this.pageLoading = false
          markPoint({ eventName: 'setPageData', measureFrom: 'pageDataReady' })
          // 路由离开了，不再处理内容
          if (isRouterLeaved) return
          this.handlePageView(true)
          this.handleQueryCartData()
        }
      }
    },
    handlePageView: function (needResetSaPageInfo){
      if(needResetSaPageInfo){
        this.resetSaPageInfo()
      }
      window.sessionStorage && window.sessionStorage.removeItem('page_from')

      window.TPM?.publish('viewcart', {
        carts: this.carts,
        currency: gbCommonInfo.currency,
        prices: { totalPrice: this.cartInfo.totalPrice }
      })
      this.pageOnload( window.SaPageInfo)
      this.saAnalysisEvent()
      setTimeout(() => {
        this.handleIncentiveStart({ scenesabt: true })
      }, 0)
    },
    closeDialogOnLeaveCart(key = []) {
      key.forEach(k => {
        this.updateState({ key: k, value: false })
      })
    },
    async handleIncentiveStart(config = {}) {
      if (typeof window == 'undefined') return

      const { cartLureInfo } = this.checkoutState

      exposeScenesAbtAggregator.addEvent({
        daId: '1-8-7-60',
        extraData: {
          benefitpop_available_point: cartLureInfo.biData?.benefitPopAvailablePoint || '-',
          type: 'benefit_pop'
        }
      })

      if (!this.canShowIncentivePopover) return

      const lastCartLure = this.getLastLureBubble(false)

      // 等待引导完成
      await cartGuideManager.isCompletedForAll()
      checkoutIncentive.initialize(cartLureInfo, { 
        mode: this.cartAbtInfo?.BenefitLinkage?.param?.Cart_Benefit_Linkage || 'OFF',
        popTiming: this.cartAbtInfo?.CartBtnPopTiming?.param?.PopTiming || 'OFF',
      })

      if (config.scenesabt && lastCartLure) {
        const cartLure = checkoutIncentive.cartBubble.getNextBubble()
        if (cartLure && lastCartLure.type === cartLure.uniqueType) {
          exposeScenesAbtAggregator.addEvent({
            daId: '1-8-7-60',
            extraData: {
              scenes: 'benefit_linkage_same',
              type: '-'
            }
          })
        }
      }
    },
    handleIncentiveEnd() {
      checkoutIncentive.stop({ hide: true })
    },
    handleQueryCartData () {
      nextTick(() => {
        const n = this.carts
        markPoint({ eventName: 'setPageDataNextTick', measureFrom: 'setPageData' })

        if (n && n.length > 0) {
          nextTick(() => {
            // 买折限购报错
            let ids = getQueryString({ key: 'isDiscountLimit' }) || getQueryString({ key: 'isFlashLimit' })
            if (ids) {
              let LimitIds = []
              let promotionOver = {}
              n.filter(cartItem => [24].includes(+cartItem.aggregateProductBusiness?.type_id) && cartItem.aggregateProductBusiness?.overLimit == 1).forEach(item => {
                if(!promotionOver[item.aggregateProductBusiness?.promotion_id]) {
                  promotionOver[item.aggregateProductBusiness?.promotion_id] = true
                  LimitIds.push(item.aggregateProductBusiness?.promotion_id)
                }
              })

              if( LimitIds.length ){
                setTimeout(function () {
                  cartEmphasizePromotionAll( LimitIds, { shake: true, changeColor: true, scroll: true })
                }, 1)
              }
            }
          })
        }
      })
    },
    onBuyBoxDrawerReady() {
      this.updateState({
        key: 'buyBoxDrawerInstance',
        value: this.$refs?.buyBoxDrawerInstance || null
      })
    },
    onBuyBoxOpen() {
      this.registerModalState()
    },
    onBuyBoxClose() {
      this.updateModalState()
      this.updateState({
        key: 'buyBoxPriceTop',
        value: {}
      })
    },
    openQuickView(productInfo) {
      const cartHideEstimatedPrice = productInfo?.pretreatInfo?.suggestedSalePriceConfigInfo?.config?.cartHideEstimatedPrice || false
      this.sameSellersLabelEvent?.openQuickAddCartReplace(
        {
          goods_id: productInfo?.goods_id,
          mallCode: productInfo?.mall_code,
          featureConfig: {
            showEstimatedPrice: !cartHideEstimatedPrice,
          },
          detailUrlExtendParam: {
            showFeedbackRec: true,
          },
          analysisConfig: {
            index: productInfo?.index,
            code: 'buy_box',
            source: productInfo?.target || null,
            sa: {
              activity_from: 'buy_box'
            }
          }
        },
        { value: this.buyBoxPriceTop.data},
        null,
        true,
        'buybox'
      )
    },
    handleBeforeUnload() {
      // 在刷新时记录保存至session
      const { rt_f_cart_clk = '', rt_f_cart_delete = '' } = getUserActionTrackerData('cart_v2').rec_ctx
      window.sessionStorage.setItem(
        'cart_v2_track_info_before_refresh',
        JSON.stringify({ rt_f_cart_clk, rt_f_cart_delete })
      )
    },
    onTouchStart() {
      if (this.cartAbtInfo?.CartBtnPopTiming?.param?.PopTiming == 'B') {
        checkoutIncentive.triggerHide({ forceHide: false })
      }
    },
  }
})
</script>
<style lang="less" scoped>
.c-skeleton__cart-container {
    background: white;
    position: fixed;
    z-index: 10000; /* stylelint-disable-line declaration-property-value-blacklist */
    width: 10rem;
    height: 100%;
    top: 1.17rem;
}
.cart-app{
  padding-bottom: 6rem;
}
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.vue-slide-layer-bottom-fade-enter-active,
.vue-slide-layer-bottom-fade-leave-active {
  transition: all 0.5s;
}

.vue-slide-layer-bottom-fade-enter-from,
.vue-slide-layer-bottom-fade-leave-active {
  transform: translate3d(0,100%,@tranlatez-transform1)!important; /* stylelint-disable-line declaration-no-important */
}
</style>
<style lang="less">
.cart-hidden-to-body {
  overflow: hidden;
}
.cart-container__recommend {
  margin-top: .267rem;
}
.cart-header {
  position: sticky;
  top: 0px;
  z-index: @zindex-header;
  background-color: #fff;
}

/* 定义关键帧动画 */
@keyframes highlight {
  /* 动画分四个阶段：从原始色到高亮色，再回到原始色，然后循环两次 */
  0%, 50%, 100% {
    background: transparent;
    z-index: 99;
  }
  25%, 75% {
    background: rgba(yellow, 0.1);
    z-index: 99;
  }
}

/* 应用动画到具体目标元素上 */
.highlight-animate {
  position: relative;
  /* 整个动画持续 800ms (400ms 高亮 * 2)，不延迟开始，执行一次 */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -99;
    animation: highlight 800ms linear 0s 1;
    pointer-events: none;
  }
}
.cart-mask{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-6px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {

    transform: translate3d(6px, 0, 0);
  }
}
.promotion-header-shake {
  animation-duration: 1s;
  animation-name: shake;
}
.page-cart-uptop{
  z-index: 11;
}
</style>
